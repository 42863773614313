<template>
  <div>
    <transition name="mask">
      <div class="side-bar-mask" @click.self.prevent="close"></div>
    </transition>
    <transition name="side">
      <div class="side-bar">
        <div class="side-bar-close-icon" @click="close">
          <img src="@/assets/icon/cancel.svg"  alt=""/>
        </div>
        <ul class="side-bar-list">
          <side-bar-list-item
            v-for="item in list"
            :key="item.text"
            :text="item.text"
            :hover-text="item.hoverText"
            :path="item.path"
            :login="item.login"
          ></side-bar-list-item>
        </ul>
        <div class="w-1/3 md:w-auto"><img :src="$store.state.siteConfig.logo"  alt=""/></div>
      </div>
    </transition>
  </div>
</template>

<script>
import SideBarListItem from "./components/SiderBarListItem";
export default {
  components: { SideBarListItem },
  data() {
    return {
      list: [
        {
          path: "/",
          text: "首页",
          hoverText: "Home",
          login: false
        },
        {
          path: "/introduction/1",
          text: "品牌介绍",
          hoverText: "Introduction",
          login: false
        },
        {
          path: "/product",
          text: "产品实力",
          hoverText: "Product",
          login: false
        },
        {
          path: 'news',
          text: '品牌新闻',
          hoverText: 'News',
          login: false
        },
        // {
        //   path: "/feedback",
        //   text: "求美者反馈",
        //   hoverText: "Feedback",
        // },
        // {
        //   path: "/nursing",
        //   text: "护理指南",
        //   hoverText: "Nursing",
        // },
        {
          path: "/partners",
          text: "合作机构",
          hoverText: "Partners",
          login: false
        },

        {
          path: "/download",
          text: "共享中心",
          hoverText: "Download",
          login: true
        },
      ],
    };
  },
  methods: {
    close() {
      this.$store.commit("setSideState", false);
    },
  },
};
</script>
<style scoped>
.side-bar-mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
}
.side-bar {
  position: absolute;
  top: 0;
  right: 0;
  width: 500px;
  height: 100vh;
  padding: 44px;
  box-sizing: border-box;
  background: white;
  border-radius: 10px 0 0 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}
.side-bar-close-icon {
  cursor: pointer;
}
.side-bar-list {
  flex: 1;
  flex-shrink: 0;
  color: #445356;
  @apply mt-5 md:mt-11;
}
.side-bar-list li {
  width: 100%;
  text-align: right;
  list-style: none;
  cursor: pointer;
  @apply mb-5;
}
</style>