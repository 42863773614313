import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        // 色相反转
        invert: false,
        // 亮度增强
        brightness: 100,
        // 网站配置
        siteConfig: {},
        // 侧边栏
        sideState: false,
        // 登录框
        loginBox: false,
        // 登录状态
        userInfo: null,

        introduction: []
    },
    getters: {},
    mutations: {
        setSiteConfig(state, data) {
            state.siteConfig = data
        },
        changeInvert(state) {
            const html = document.getElementsByTagName('html')[0]
            state.invert = !state.invert
            if (state.invert) {
                html.style.filter = html.style.filter += ' invert(1)'
            } else {
                html.style.filter = html.style.filter.replace('invert(1)', '')
            }
        },
        changeBrightness(state) {
            const html = document.getElementsByTagName('html')[0]
            switch (state.brightness) {
                case 50:
                    state.brightness = 100
                    html.style.filter = html.style.filter.replace('brightness(50%)', '')
                    html.style.filter = html.style.filter += ' brightness(100%)'
                    break;
                case 100:
                    state.brightness = 150
                    html.style.filter = html.style.filter.replace('brightness(100%)', '')
                    html.style.filter = html.style.filter += ' brightness(150%)'
                    break;
                case 150:
                    state.brightness = 50
                    html.style.filter = html.style.filter.replace('brightness(150%)', '')
                    html.style.filter = html.style.filter += ' brightness(50%)'
                    break;
            }
        },
        setSideState(state, data) {
            state.sideState = data
        },
        setLoginBoxState(state, data) {
            state.loginBox = data
        },
        setUserInfo(state, data) {
            state.userInfo = data
        },
        setIntroduction(state, data) {
            state.introduction = data
        }
    },
    actions: {},
    modules: {}
})