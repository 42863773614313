<template>
  <div style='overflow-y: auto;'>
    <div class="fixed left-0 right-0 top-0 z-40" ref="navbar">
      <nav-bar class="bg-white"></nav-bar>
    </div>
    <full-page :options="swiperOptions">
      <!-- 第一屏 Start -->
      <full-page-section>
        <el-carousel class="col-span-full" >
          <el-carousel-item v-for="item in this.bannerList" :key="item">
            <el-image
                class="elimage"
                style="width: 100%;"
                :src="item"
                fit="cover"></el-image>
          </el-carousel-item>
        </el-carousel>
<!--        <div-->
<!--          class="-->
<!--            col-span-5-->
<!--            xl:col-span-4-->
<!--            text-content-dark text-2xl-->
<!--            md:text-3xl md:leading-normal-->
<!--            lg:text-4xl lg:leading-normal-->
<!--            2xl:text-5xl 2xl:leading-normal-->
<!--            font-bold-->
<!--            flex flex-col-->
<!--            justify-center-->
<!--          "-->
<!--        >-->
<!--          <div>呵护您的每一寸肌肤</div>-->
<!--          <div>引领绿色护肤新革命！</div>-->
<!--        </div>-->
<!--        <div class="flex-1 col-span-4 xl:col-span-5 mt-8">-->
<!--          <div-->
<!--            class="w-full h-full bg-contain bg-no-repeat bg-center"-->
<!--            :style="{ backgroundImage: `url(${require('@/assets/home.png')})` }"-->
<!--          ></div>-->
<!--        </div>-->
      </full-page-section>
      <!-- 第一屏 End -->

      <!-- 第二屏 Start -->
<!--      <full-page-section-->
<!--        class="-->
<!--          bg-cover-->
<!--          lg:bg-contain-->
<!--          bg-no-repeat bg-center-->
<!--          lg:bg-right-->
<!--          bg-none-->
<!--          xl:bg-[url('@/assets/bg-element/bg02.png')]-->
<!--        "-->
<!--      >-->
<!--        <div class="text-main col-span-full">-->
<!--          <div class="text-2xl md:text-8xl font-bold md:font-normal uppercase">-->
<!--            Bestsellers-->
<!--          </div>-->
<!--          <div class="text-lg">畅销商品</div>-->
<!--        </div>-->
<!--        <div class="flex-1 hidden md:flex col-span-full gap-7 items-end">-->
<!--          <div class="w-52 md:w-64" v-for="item in sellWellList" :key="item.id">-->
<!--            <product-exhibition-->
<!--              class="bg-main"-->
<!--              title-color="white"-->
<!--              :product="item"-->
<!--            ></product-exhibition>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="flex-1 grid md:hidden grid-rows-3 w-full gap-4 mt-4">-->
<!--          <div-->
<!--            class="w-full p-3 shadow-md rounded-md flex relative"-->
<!--            v-for="item in sellWellList"-->
<!--            :key="item.id"-->
<!--            @click="gotoProduct(item.id)"-->
<!--          >-->
<!--            <div-->
<!--              class="bg-gray-200 rounded-md aspect-square bg-cover bg-center"-->
<!--              :style="{ backgroundImage: `url(${item.cover})` }"-->
<!--            ></div>-->
<!--            <div class="ml-2">-->
<!--              <div class="md:text-lg text-content-dark">{{ item.title }}</div>-->
<!--              <div class="text-sm md:text-base text-content-light">-->
<!--                {{ item.title_en }}-->
<!--              </div>-->
<!--            </div>-->
<!--            <div-->
<!--              class="-->
<!--                absolute-->
<!--                bottom-4-->
<!--                right-4-->
<!--                w-8-->
<!--                h-8-->
<!--                rounded-full-->
<!--                bg-[url('@/assets/icon/right.png')]-->
<!--                bg-accent-blue-->
<!--                bg-contain-->
<!--                bg-no-repeat-->
<!--                bg-center-->
<!--              "-->
<!--            ></div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </full-page-section>-->
      <!-- 第二屏 End -->

      <!-- 第三屏 Start -->
<!--      <full-page-section-->
<!--        class="-->
<!--          bg-cover bg-no-repeat bg-center bg-none-->
<!--          -->
<!--        "-->
<!--      >-->
<!--        <div-->
<!--          class="-->
<!--            lg:hidden-->
<!--            w-full-->
<!--            h-1/3-->
<!--            md:h-1/2-->
<!--            col-span-full-->
<!--            mt-8-->
<!--            bg-cover-->
<!--            bg-no-repeat-->
<!--            bg-center-->
<!--            -->
<!--            flex-->
<!--            justify-center-->
<!--            items-center-->
<!--          "-->
<!--        >-->
<!--          <img-->
<!--            class="w-16 h-16"-->
<!--            src="@/assets/icon/player.png"-->
<!--            @click="gotoVideo"-->
<!--            alt=""-->
<!--          />-->
<!--        </div>-->
<!--        <div-->
<!--          class="-->
<!--            relative-->
<!--            col-span-full-->
<!--            flex flex-col-->
<!--            justify-center-->
<!--            items-center-->
<!--          "-->
<!--        >-->
<!--          <img-->
<!--            class="hidden lg:block w-16 h-16"-->
<!--            src="@/assets/icon/player.png"-->
<!--            @click="gotoVideo"-->
<!--            alt=""-->
<!--          />-->
<!--          <div-->
<!--            class="-->
<!--              text-2xl-->
<!--              lg:text-5xl-->
<!--              text-center text-content-dark-->
<!--              lg:text-white-->
<!--              mt-8-->
<!--              uppercase-->
<!--            "-->
<!--          >-->
<!--            Confidence<br />-->
<!--            that's more than just skin deep-->
<!--          </div>-->
<!--          <div class="flex justify-center mt-8 lg:mt-20">-->
<!--            <buy-btn class="transform scale-75 lg:scale-100"></buy-btn>-->
<!--          </div>-->
<!--        </div>-->
<!--      </full-page-section>-->
      <!-- 第三屏 End -->

      <!-- 第四屏 Start -->
<!--      <full-page-section>-->
<!--        <div class="text-content-dark col-span-5 lg:p-20">-->
<!--          <div class="text-2xl md:text-7xl font-bold md:font-normal uppercase">-->
<!--            Before & After Results-->
<!--          </div>-->
<!--          <div class="text-lg">之前和之后结果</div>-->
<!--          <div class="text-sm text-content-light">-->
<!--            VI Peel-->
<!--            的功能没有限制。我们中的许多人都有皮肤问题，让我告诉你，这种果皮可以立竿见影！-->
<!--            感谢 VI Peel 成为一个包容性品牌。-->
<!--          </div>-->
<!--          <div class="mt-8 lg:hidden">-->
<!--            <comparison-img width="250px" height="250px"></comparison-img>-->
<!--          </div>-->
<!--          <div class="text-sm text-content-light mt-8 lg:mt-32">-->
<!--            萨马拉，29 岁-->
<!--          </div>-->
<!--          <div class="-ml-6 lg:ml-0">-->
<!--            <buy-btn class="transform scale-75 lg:scale-100"></buy-btn>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="hidden lg:block col-span-4">-->
<!--          <comparison-img width="450px" height="450px"></comparison-img>-->
<!--        </div>-->
<!--        <div class="hidden lg:block col-span-3 h-1/2 self-end mb-16">-->
<!--          <div-->
<!--            class="w-full h-full bg-contain bg-no-repeat bg-center"-->
<!--            :style="{ backgroundImage: `url(${require('@/assets/home.png')})` }"-->
<!--          ></div>-->
<!--        </div>-->
<!--      </full-page-section>-->
      <!-- 第四屏 End -->

      <!-- 第五屏 Start -->
<!--      <full-page-section>-->
<!--        <div class="col-span-full flex flex-col">-->
<!--          <div class="text-main">-->
<!--            <div-->
<!--              class="text-2xl md:text-7xl font-bold md:font-normal uppercase"-->
<!--            >-->
<!--              Honor and Commendation-->
<!--            </div>-->
<!--            <div class="text-lg">荣誉表彰</div>-->
<!--          </div>-->
<!--          <div class="mt-24 w-full flex-1 col-span-full gap-8 hidden lg:flex">-->
<!--            <div class="w-2/5">-->
<!--              <img class="w-full" :src="honorary[current].cover" alt=""/>-->
<!--            </div>-->
<!--            <div class="flex-1 w-0">-->
<!--              <swiper-->
<!--                ref="swiper"-->
<!--                :options="swiperOptions"-->
<!--                @slideChange="onSlideChange"-->
<!--              >-->
<!--                <swiper-slide v-for="(item, index) in honorary" :key="index">-->
<!--                  <img :src="item.cover" alt=""/>-->
<!--                </swiper-slide>-->
<!--              </swiper>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="mt-24 lg:hidden">-->
<!--            <swiper :options="{}">-->
<!--              <swiper-slide v-for="(item, index) in honorary" :key="index">-->
<!--                <img :src="item.cover" alt=""/>-->
<!--              </swiper-slide>-->
<!--            </swiper>-->
<!--          </div>-->
<!--        </div>-->
<!--      </full-page-section>-->
      <!-- 第五屏 End -->

      <!-- 第六屏 Start -->
<!--      <full-page-section class="bg-main">-->
<!--        <div class="text-white col-span-4">-->
<!--          <div class="text-2xl md:text-7xl font-bold md:font-normal uppercase">-->
<!--            contact us-->
<!--          </div>-->
<!--          <div class="text-lg">联系我们</div>-->
<!--        </div>-->
<!--        <form-->
<!--          class="-->
<!--            col-span-4-->
<!--            text-white-->
<!--            lg:ml-16-->
<!--            text-xs-->
<!--            lg:text-sm lg:grid lg:grid-cols-2 lg:max-h-80-->
<!--          "-->
<!--        >-->
<!--          <label class="self-end mr-4">-->
<!--            你的姓名：<br />-->
<!--            <input-->
<!--              class="-->
<!--                p-2-->
<!--                border border-white border-opacity-50-->
<!--                bg-transparent-->
<!--                rounded-md-->
<!--                outline-none-->
<!--                lg:w-full-->
<!--              "-->
<!--              type="text"-->
<!--            />-->
<!--          </label>-->
<!--          <label class="self-end mt-2">-->
<!--            联系电话：<br />-->
<!--            <input-->
<!--              class="-->
<!--                p-2-->
<!--                border border-white border-opacity-50-->
<!--                bg-transparent-->
<!--                rounded-md-->
<!--                outline-none-->
<!--                lg:w-full-->
<!--              "-->
<!--              type="text"-->
<!--            />-->
<!--          </label>-->
<!--          <textarea-->
<!--            cols="30"-->
<!--            rows="3"-->
<!--            placeholder="请输入留言"-->
<!--            class="-->
<!--              col-span-full-->
<!--              p-2-->
<!--              w-full-->
<!--              mt-4-->
<!--              border border-white border-opacity-50-->
<!--              bg-transparent-->
<!--              rounded-md-->
<!--              outline-none-->
<!--            "-->
<!--          ></textarea>-->
<!--          <v-btn class="w-2/3 transform scale-75 m-auto">提交</v-btn>-->
<!--        </form>-->
<!--        <div class="hidden lg:block w-full col-span-4 ml-16 mt-16">-->
<!--          <img class="w-4/5" src="" alt=""/>-->
<!--        </div>-->
<!--        <footer-bar class="absolute left-0 bottom-0 right-0"></footer-bar>-->
<!--      </full-page-section>-->
      <!-- 第六屏 End -->
    </full-page>
    <FooterBar></FooterBar>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar/NavBar";
import FooterBar from "@/components/FooterBar/FooterBar";
import ProductExhibition from "@/components/Product/ProductExhibition";
import BaseBuyBtn from "@/components/BaseBuyBtn/BaseBuyBtn";
import FullPageSection from "./components/FullPageSection";
import ComparisonImg from "@/components/ComparisonImg/ComparisonImg";
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import "swiper/swiper.min.css";

export default {
  components: {
    NavBar,
    FooterBar,
    ProductExhibition,
    BuyBtn: BaseBuyBtn,
    FullPageSection,
    ComparisonImg,
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  data() {
    return {
      product: {
        title: "温和净化洁面乳",
        enTitle: "Vitamin C Brightening Concentrate",
        price: "666.66",
      },
      sellWellList: [],
      swiperOptions: {
        slidesPerView: 4,
        loop: true,
        spaceBetween: 30,
      },
      current: 1,
      honorary: [],
      bannerList: []
    };
  },
  mounted() {
    console.clear();
    this.getSellWell();
    this.getHonorary();
    this.getBanner()
  },
  methods: {
    gotoVideo() {
      this.$router.push("/video");
    },
    gotoProduct(id) {
      this.$router.push("/product/" + id);
    },
    // 第二屏畅销商品
    getSellWell() {
      this.axios.post("/index/home_product_list").then((res) => {
        this.sellWellList = res.data.data.slice(0, 3);
      });
    },
    // 第五屏 荣誉表彰
    getHonorary() {
      this.axios.post("/index/honor").then((res) => {
        this.honorary = res.data.data;
      });
    },

    getBanner() {
      this.$api.banner()
          .then(res => {
            this.bannerList = res[0].imgurl
          })
    },

    onSlideChange({ realIndex }) {
      this.current = realIndex - 1 < 0 ? this.imgs.length - 1 : realIndex - 1;
    },
  },
};
</script>

<style lang="scss">
.wrap {
  @apply h-full;
}
.banner {
  @apply grid-rows-6 md:grid-rows-4 lg:grid-rows-3 md:gap-10;

  &__content {
    @apply ml-3.5 md:ml-12 2xl:ml-24 mt-4 md:mt-0;
    @apply col-span-full md:col-span-7 lg:col-span-5 2xl:col-span-4;
    @apply md:row-start-2;
    @apply text-3xl md:text-4xl xl:text-5xl font-bold text-content-dark;
  }

  &__img {
    @apply mt-24 mb-20;
    @apply col-span-full md:col-span-4;
    @apply lg:col-start-6 2xl:col-start-5;
    @apply row-span-4 md:row-span-3 lg:row-span-full;
    @apply bg-no-repeat bg-contain bg-center;
  }

  &__next {
    @apply flex flex-row-reverse gap-4.5 lg:flex-col items-center;
    @apply mr-3.5 mb-3.5 md:mb-0 md:mr-12 lg:mt-12;
    @apply col-span-full lg:col-span-3;
    @apply lg:col-start-10;
    @apply md:row-start-4 lg:row-start-1;
    @apply text-right text-sm md:text-base xl:text-xl text-content-dark;
  }
}
.fp-tableCell , .fp-table {
  height: 700px !important;
}
.el-carousel__container {
    height: 640px !important;
}
</style>