import axios from "axios";

const request = axios.create({
    baseURL: 'https://www.vipeel.com.cn/api',
    timeout: 3000
})

// 处理请求
request.interceptors.request.use(config => {
    return config
}, error => {
    Promise.reject(error)
})

// 处理响应
request.interceptors.response.use(res => {
    if (res.data.code === 200) {
        return Promise.resolve(res.data.data)
    } else {
        return Promise.reject(res.data)
    }
}, error => {
    Promise.reject(error)
})

export default request