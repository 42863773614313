<template>
  <div>
    <div class="container">
      <VTitle class="text-content-dark my-12" main="Feedback" sub="求美者反馈"></VTitle>
      <div @mouseenter="clearAutoPlay(); noSwiping = true" @mouseleave="setAutoPlay(); noSwiping = false">
        <swiper @slideChange="onSlideChange" ref="swiper" :options="swiperOptions">
          <swiper-slide v-for="item in list" :key="item.id" :class="{'swiper-no-swiping': noSwiping}">
            <div class="grid-wrap py-2 px-3 lg:py-5 lg:px-6">
              <VImg height="60%" :src="item.cover" class="col-span-full lg:col-span-5 rounded-xl overflow-hidden" fit="cover"></VImg>
              <div class="static col-span-full lg:col-span-7 lg:col-start-1 lg:row-start-1">
                <div class="text-lg lg:text-3xl text-content-dark mt-3">{{ item.title }}</div>
                <div class="text-sm lg:text-xl text-content-light mt-1 lg:mt-2">{{ item.create_date }}</div>
                <div class="text-sm lg:text-xl text-content-light my-3 lg :my-6 lg:mr-24">{{ item.profile }}</div>
                <div class="bg-main inline-flex gap-2.5 items-center text-xs lg:text-base text-white py-3 lg:py-4.5 px-4 lg:px-5 rounded-lg cursor-pointer" @click="gotoUrl(item.url)">
                  查看更多
                  <img class="w-3 lg:w-6" src="@/assets/icon/arrow.svg" alt="" />
                </div>
              </div>
            </div>
          </swiper-slide>
          <div slot="pagination">
            <div class="ml-4 lg:ml-6 flex gap-3 cursor-pointer">
              <div v-for="index in list.length" :key="index" @click="slideTo(index)" class="w-2 lg:w-4 h-2 lg:h-4 rounded-full" :class="current === index ? 'bg-main' : 'bg-gray-200'"></div>
            </div>
          </div>
        </swiper>
      </div>
      <div class="mt-6">
        <div class="grid-wrap py-2 px-3 lg:py-5 lg:px-6" v-for="item in list" :key="item.id">
          <VImg height="66%" :src="item.cover" class="col-span-full lg:col-span-5 rounded-xl overflow-hidden" fit="cover"></VImg>
          <div class="static col-span-full lg:col-span-7 lg:ml-6">
            <div class="text-lg lg:text-3xl text-content-dark mt-3">{{ item.title }}</div>
            <div class="text-sm lg:text-xl text-content-light mt-1 lg:mt-2">{{ item.create_data }}</div>
            <div class="text-sm lg:text-xl text-content-light my-3 lg :my-6 lg:mr-24">{{ item.profile }}</div>
            <div class="bg-main inline-flex gap-2.5 items-center text-xs lg:text-base text-white py-3 lg:py-4.5 px-4 lg:px-5 rounded-lg cursor-pointer" @click="gotoUrl(item.url)">
              查看更多
              <img class="w-3 lg:w-6" src="@/assets/icon/arrow.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="my-12 flex justify-end">
        <div class="w-full md:w-100">
          <VPager :total="Math.ceil(total / 3)" @change="onPagerChange"></VPager>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import "swiper/swiper.min.css";
import VPager from "@/components/VPager/VPager";

export default {
  data() {
    return {
      current: 1,
      swiperOptions: {
        loop: true,
        speed: 1000
      },
      autoplay: 3000, // 自动切换间隔时间 (ms)
      noSwiping: false,
      list: [],
      total: 10,
      page: 1,
    };
  },
  components: {
    VPager,
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  mounted() {
    this.setAutoPlay()
    this.getList()
  },
  methods: {
    setAutoPlay() {
      if (this.autoplay) {
        this.clearAutoPlay()
        this.autoplayTimer = setTimeout(() => {
          this.slideTo(this.current + 1)
        }, this.autoplay + this.swiperOptions.speed)
      }
    },
    clearAutoPlay() {
      clearTimeout(this.autoplayTimer)
    },
    onSlideChange({ activeIndex }) {
      // 如果大于数组长度时 取 1
      // 如果小于 1 时 取数组长度
      if (activeIndex > this.list.length) {
        this.current = 1
      } else if (activeIndex < 1) {
        this.current = this.list.length
      } else {
        this.current = activeIndex
      }
      this.slideTo(this.current)
      this.setAutoPlay()
    },
    slideTo(index) {
      this.$refs.swiper.$swiper.slideTo(index);
    },
    getList() {
      this.$api.communicationList({
        page: this.page,
        class: 2
      })
          .then(res => {
            this.total = res.total
            this.list = res.data
          })
    },
    gotoUrl(url) {
      window.open(url)
    },
    onPagerChange(page) {
      this.page = page
      this.getList()
    }
  },
};
</script>