<template>
  <div
      class="
              w-52
              pt-2
              bg-white
              rounded-xl
              shadow-2xl
              user-box
              cursor-default
            "
  >
    <div
        class="
                flex
                justify-center
                items-center
                w-16
                h-16
                mt-3
                mx-auto
                rounded-full
                overflow-hidden
              "
    >
      <img
          class="w-16 h-16"
          :src="avatar"
          alt="头像"
      />
    </div>
    <div class="my-2 font-bold text-content-dark text-center">
      {{ nickname }}
    </div>
    <div class="flex justify-center items-center border-t p-3">
      <div
          class="
                  flex
                  justify-center
                  items-center
                  bg-content-light bg-opacity-5
                  py-2
                  px-5
                  rounded-full
                  cursor-pointer
                  text-sm text-content-light
                "
          @click.stop="$emit('logout')"
      >
        <div
            class="w-4.5 h-4.5 bg-cover"
            :style="{
                    backgroundImage: `url(${require('@/assets/icon/logout.png')})`,
                  }"
        ></div>
        退出登录
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "User",
  data() {
    return {
      nickname: '',
      avatar: ''
    }
  },
  mounted() {
    const user = this.$cookies.get('user')
    this.nickname = user.nickname
    this.avatar = user.avatar
  }
}
</script>

<style scoped>

</style>