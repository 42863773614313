<template>
  <div class="w-full relative h-0" :style="style">
    <div class="absolute inset-0">
      <ElImage class="w-full h-full" :class="customClass"
               :src="src"
               :fit="fit"
               :alt="alt"
               :referrer-policy="referrerPolicy"
               :lazy="lazy"
               :scroll-container="scrollContainer"
               :preview-src-list="previewSrcList"
               :z-index="zIndex"
               @load="$emit('load', $event)"
               @error="$emit('error', $event)"
      >
        <template slot="error">
          <slot name="error"></slot>
        </template>
        <template slot="placeholder">
          <slot name="placeholder"></slot>
        </template>
      </ElImage>
    </div>
  </div>
</template>

<script>
export default {
  name: "VImg",
  props: ['height', 'customClass', 'src', 'fit', 'alt', 'referrerPolicy', 'lazy', 'scrollContainer', 'previewSrcList', 'zIndex'],
  computed: {
    style() {
      return {
        paddingBottom: this.height
      }
    }
  }
}
</script>