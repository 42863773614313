<template>
  <div class="w-full pb-8 bg-white transition absolute border-b" :class="[show ? 'translate-y-0' : '-translate-y-150']">
    <div v-if="type === 'product'" class="container flex gap-16">
      <div v-for="pClass in productClass" :key="pClass.id">
        <div class="text-xl font-bold text-main bg-">{{ pClass.title }}</div>
        <ElDivider></ElDivider>
        <ul class="flex flex-col gap-4">
          <li class="cursor-pointer text-sm text-content-dark hover:font-bold hover:text-main" v-for="item in pClass.products" :key="item.id" @click="gotoDetail(item.id)">
            {{ item.title }}
          </li>
        </ul>
      </div>
    </div>
    <div v-if="type === 'introduction'" class="container flex gap-16">
      <div @click="gotoContent(index)" v-for="(item, index) in introduction" :key="index" class="text-sm text-content-dark hover:font-bold hover:text-main cursor-pointer">
        {{item.title}}
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: 'SubNav',
  data() {
    return {
      show: false,
      type: '',
      productClass: []
    }
  },
  created() {
    this.$api.productClass()
        .then(res => {
          this.productClass = res
        })
  },
  computed: {
    ...mapState(['introduction'])
  },
  methods: {
    open(type) {
      this.type = type
      this.show = true
    },
    close() {
      this.show = false
    },
    gotoDetail(id) {
      this.$router.push('/product/' + id)
    },
    gotoContent(index) {
      this.$router.push('/introduction/' + index)
    }
  }
}
</script>