<template>
  <div id="app">
    <SideBar class="side-bar-a" v-show="$store.state.sideState"></SideBar>
    <Login v-if="$store.state.loginBox"></Login>
    <NavBar v-show="show"></NavBar>
    <router-view />
    <FooterBar v-show="show"></FooterBar>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar/NavBar";
import SideBar from "@/components/SideBar/SideBar";
import FooterBar from "@/components/FooterBar/FooterBar";
import Login from "@/components/Login/Login";
export default {
  components: {Login, NavBar, FooterBar, SideBar },
  computed: {
    show() {
      const routeName = this.$route.name;
      // 除了home都显示
      return routeName !== "home";
    },
  },
  created() {
    this.getSiteConfig();
    this.$api.introduction()
        .then(res => {
          this.$store.commit('setIntroduction', res.config)
        })
  },
  methods: {
    getSiteConfig() {
      this.$api.getSiteConfig()
          .then(res => {
            this.$store.commit('setSiteConfig', res)
          })
    },
  },
};
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
  max-width: 100vw;
  background: white;
}
html.invert {
  filter: invert(100%);
}
html.brightness-50 {
  filter: brightness(50%);
}
html.brightness-100 {
  filter: brightness(100%);
}
html.brightness-150 {
  filter: brightness(150%);
}
.side-bar-a {
  @apply fixed top-0 left-0 bottom-0 right-0 z-50;
}
</style>
