<template>
  <div class="my-6 md:my-16">
    <div class="container">
      <div class="grid-wrap gap-4 md:gap-6 xl:gap-12">
        <div class="col-span-6 lg:col-span-4" v-for="item in productList" :key="item.id">
          <ProductItem
              :cover="item.cover"
              :name="item.title"
              :subtitle="item.title_en"
              :price="item.price"
              @click="gotoProductDetail(item.id)"
          ></ProductItem>
        </div>
      </div>
      <div class="mt-7 md:mt-14 flex justify-end">
        <div class="w-full md:w-100">
          <VPager :total="Math.ceil(total / 6)" @change="changePage"></VPager>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductItem from "./components/ProductItem";
import VPager from "@/components/VPager/VPager";

export default {
  components: { ProductItem, VPager },
  data() {
    return {
      total: 10,
      productList: [],
      params: {
        page: 1
      },
    };
  },
  created() {
    this.getProductList()
  },
  methods: {
    getProductList() {
      this.$api.productList(this.params)
          .then(res => {
            this.productList = res.product_list.data
            this.total = res.product_list.total
          })
    },
    gotoProductDetail(id) {
      this.$router.push('/product/' + id)
    },
    changePage(i) {
      this.params.page = i
      this.getProductList()
    }
  },
};
</script>

<style lang="scss" scoped>
.product-selector {
  @apply hidden lg:block;
  @apply sticky w-40 h-full top-32 mr-6 flex-shrink-0;

  &--title {
    @apply text-base font-bold text-content-dark mt-6;
  }

  &--item {
    @apply h-12 text-sm pl-4 mt-2.5;
    @apply flex justify-start items-center;
    @apply bg-content-dark bg-opacity-5 cursor-pointer rounded-xl transition-colors;

    &:hover {
      // 鼠标悬浮时
      @apply bg-content-light bg-opacity-50;
    }

    &.active {
      // 被激活时
      background: url("@/assets/tick.png") no-repeat 127px center;
      @apply text-white font-bold bg-main;
    }
  }
}
</style>