import {
    Image,
    Divider,
    Tabs,
    TabPane,
    Timeline,
    TimelineItem,
    Carousel,
    CarouselItem
} from 'element-ui'

export default {
    install(Vue) {
        Vue.use(Image)
        Vue.use(Divider)
        Vue.use(Tabs)
        Vue.use(TabPane)
        Vue.use(Timeline)
        Vue.use(TimelineItem)
        Vue.use(Carousel)
        Vue.use(CarouselItem)
    }
}