<template>
  <div class="section relative">
    <div class="h-full flex flex-col">
      <div class="h-16 md:h-24"><!-- 导航栏预留 --></div>
      <div
        class="
          flex-1 flex flex-col
          lg:grid lg:grid-cols-12
          relative
        "
      >
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>