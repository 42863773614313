<template>
  <div class="box" @click="onclick">
    <slot></slot>
  </div>
</template>

<script>
export default {
  methods: {
    onclick() {
      this.$emit("click");
    },
  },
};
</script>

<style scoped>
.box {
  overflow: hidden;
  border-radius: 6px;
  transition: 0.5s;
}
.box:hover {
  box-shadow: 0 20px 30px -15px rgba(10, 10, 10, 0.1);
}
</style>