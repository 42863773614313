import api from './request'

export default {
    /*** 网站基本设置 */
    getSiteConfig: () => api.post('/index/siteCofing'),

    /*** 首页轮播图 */
    banner: () => api.post('/index/banner'),

    /*** 导航栏：产品分类 */
    productClass: () => api.post('/index/home_product_class'),

    /*** 产品：列表 */
    productList: params => api.post('/product/index', params),
    /*** 产品：详情 */
    productDetail: params => api.post('/product/info', params),

    /*** 品牌新闻，求美者反馈：列表 */
    communicationList: params => api.post('/product/CommunicationList', params),

    /*** 品牌介绍 */
    introduction: () => api.post('/index/about'),

    /*** 合作机构：省市列表 */
    province: () => api.post('/product/province'),
    city: city_pid => api.post('/product/city', { city_pid }),
    /*** 合作机构：搜索 */
    partner: params => api.post('/product/partnerSearch', params),

    /*** 登录：获得小程序码 */
    getQrcode: () => api.post('/auth/xcxAuth'),
    getPCToken: auth_key => api.post('/index/getPcToken', { auth_key }),

    /*** 共享中心：文件列表 */
    downloadList: (params, headers) => api.post('/download.index/downloadCenter', params, { headers }),

    /*** 共享中心：视频列表 */
    videoList: params => api.post('/product/videoList', params)
}