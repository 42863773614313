<template>
  <div class="comparison-img-wrap" :style="{ width, height }">
    <img :style="{ width, height }" :src="src1" />
    <div class="comparison-top" :style="{ width: floatWidth + '%' }">
      <img :style="{ width, height }" :src="src2" />
    </div>
    <div class="comparison-line" :style="{ left: floatWidth + '%' }">
      <div class="line"></div>
      <div class="mid"></div>
      <div class="line"></div>
    </div>
    <input
      class="comparison-range"
      type="range"
      max="100"
      min="0"
      v-model="floatWidth"
    />
  </div>
</template>

<script>
export default {
  props: {
    src1: String,
    src2: String,
    width: String,
    height: String,
    default: {
      default: 50,
    },
  },
  data() {
    return {
      floatWidth: this.default,
    };
  },
};
</script>
<style scoped>
.comparison-img-wrap {
  position: relative;
  border-radius: 12px;
  overflow: hidden;
}
.comparison-img-wrap img {
  max-width: none;
}
.comparison-top {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}
.comparison-range {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: col-resize;
}
.comparison-line {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.comparison-line .line {
  width: 4px;
  height: 100%;
  background: white;
}
.comparison-line .mid {
  width: 40px;
  min-height: 40px;
  border-radius: 50%;
  border: 4px solid white;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.comparison-line .mid::before,
.comparison-line .mid::after {
  content: "";
  display: block;
  width: 8px;
  height: 15px;
  background: white;
}
.comparison-line .mid::before {
  clip-path: polygon(100% 0, 0 50%, 100% 100%);
}
.comparison-line .mid::after {
  clip-path: polygon(0 0, 100% 50%, 0 100%);
}
</style>