<template>
  <li @mouseenter="hover = true" @mouseleave="hover = false">
    <div class="list-item" @click="jump">
      <p class="text" :class="{ active: hover || selected(path) }">
        {{ text }}
      </p>
      <p class="hover-text" :class="{ active: hover && !selected(path) }">
        {{ hoverText }}
      </p>
      <div class="line" v-if="selected(path)"></div>
    </div>
  </li>
</template>

<script>
export default {
  props: {
    text: String,
    hoverText: String,
    path: String,
    login: Boolean
  },
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    selected() {
      return (path) => {
        const routeName = this.$route.path;
        return path === routeName;
      };
    },
  },
  methods: {
    jump() {
      this.$store.commit("setSideState", false);
      if (this.login && !this.$cookies.get('token')) {
        this.$store.commit('setLoginBoxState', true)
        return
      }
      this.$router.push(this.path);
    },
  },
};
</script>
<style scoped>
.list-item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 36px;
}
.line {
  margin-left: 12px;
  width: 167px;
  height: 1px;
  background: #8b9b9e;
}
.text {
  position: relative;
  z-index: 1;
  transition: 0.4s;
  @apply text-base lg:text-xl;
}
.text.active {
  color: #8b9b9e;
}
.hover-text {
  position: absolute;
  bottom: 10px;
  right: 0;
  color: #f7f7fa;
  font-weight: bold;
  margin: 0;
  opacity: 0;
  transition: 0.4s;
  white-space: nowrap;
  user-select: none;
  @apply text-4xl md:text-6xl;
}
.hover-text.active {
  opacity: 1;
}
</style>