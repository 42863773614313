<template>
  <div class="foo-bar">
    <div class="company">
      <div class="company-info">
<!--        <div class="company&#45;&#45;name">{{ siteConfig.seo_title }}</div>-->
        <div class="company-info--item">电话:&emsp;{{ siteConfig.tel }}</div>
        <div class="company-info--item">邮箱:&emsp;{{ siteConfig.mail }}</div>
        <div class="company-info--item">
          地址:&emsp;{{ siteConfig.address }}
        </div>
      </div>
      <div class="company-qrcode">
        <div class="qrcode-item">
          <img :src="siteConfig.qrcode"  alt=""/>
          <div class="qrcode-name">微信公众号</div>
        </div>
        <div class="qrcode-item">
          <img :src="siteConfig.logo3"  alt=""/>
          <div class="qrcode-name">护理小程序</div>
        </div>
        <div class="qrcode-item">
          <img :src="siteConfig.logo2"  alt=""/>
          <div class="qrcode-name">维塔丽商城</div>
        </div>
      </div>
    </div>
    <div class="dividing-line"></div>
    <div class="copyright">
      <div>{{ siteConfig.copyright }}</div>
      <div class="text-center"><a target="_blank" href="https://beian.miit.gov.cn/">{{siteConfig.record}}</a></div>
      <div class="text-right">服务条款&emsp;|&emsp;免责声明</div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["siteConfig"]),
  },
};
</script>

<style lang="scss" scoped>
.foo-bar {
  background-color: #2e3334;
  @apply w-full text-white p-6;
  @apply xl:py-12 xl:px-48;
}

.company {
  @apply flex flex-col md:flex-row md:justify-between md:items-center;

  .company-info {
    .company--name {
      @apply text-lg md:text-xl md:font-medium md:mb-8;
    }
    &--item {
      @apply text-xs md:text-sm mb-1 md:mb-5;
    }
  }

  .company-qrcode {
    @apply hidden;
    @apply md:block;

    .qrcode-item {
      display: inline-block;
      width: 100px;
      margin-left: 16px;
      text-align: center;
    }

    .qrcode-name {
      font-size: 12px;
      margin-top: 0;
      line-height: 18px;
    }
  }
}

.dividing-line {
  height: 1px;
  @apply my-1 md:my-6 w-full bg-white bg-opacity-50;
}

.copyright {
  @apply flex flex-col text-xs items-end;
  @apply md:flex-row justify-between;

  & > div {
    @apply mt-1 flex-1;
  }
}
</style>