<template>
  <div class="grid grid-cols-8 gap-3">
    <div v-for="item in seat" :key="item + 'seat'"></div>
    <div class="pager-item" @click="changePage(cur - 1)">
      <div class="pre" style="padding-bottom: 100%"></div>
    </div>
    <div class="pager-item" v-show="startEllipsis">
      <div class="ellipsis" style="padding-bottom: 100%"></div>
    </div>

    <div
      class="pager-item"
      :class="{ active: item === cur }"
      v-for="item in pieces"
      :key="item + 'pieces'"
      @click="changePage(item)"
    >
      <div class="relative" style="padding-bottom: 100%">
        <div class="absolute w-full h-full flex justify-center items-center">
          {{ item }}
        </div>
      </div>
    </div>

    <div class="pager-item" v-show="lastEllipsis">
      <div class="ellipsis" style="padding-bottom: 100%"></div>
    </div>
    <div class="pager-item" @click="changePage(cur + 1)">
      <div class="next" style="padding-bottom: 100%"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["total"],
  data() {
    return {
      totalPage: 0,
      cur: 1,
      startEllipsis: false,
      lastEllipsis: false,
    };
  },
  mounted() {
    this.totalPage = this.total
  },
  watch: {
    total() {
      this.totalPage = this.total
    },
    totalPage() {
      if (this.totalPage > 6) {
        this.lastEllipsis = true;
      }
    },
    cur(nv) {
      if (this.totalPage > 6) {
        this.startEllipsis = nv >= 4;
        this.lastEllipsis = nv < this.total - 3;
      }
    },
  },
  computed: {
    pieces() {
      const { totalPage, cur } = this;
      let arr;
      if (totalPage > 6) {
        if (cur >= 4 && cur < totalPage - 3) {
          arr = new Array(4)
            .fill(1)
            .map((item, index) => item + index + cur - 2);
        } else if (cur >= totalPage - 3) {
          arr = new Array(5)
            .fill(totalPage)
            .map((item, index) => item + index - 4);
        } else {
          arr = new Array(5).fill(1).map((item, index) => item + index);
        }
      } else {
        // 返回全部
        arr = new Array(totalPage).fill(1).map((item, index) => item + index);
      }

      return arr;
    },
    seat() {
      return 6 - this.totalPage < 0 ? 0 : 6 - this.totalPage;
    },
  },
  methods: {
    reset() {
      this.cur = 1;
    },
    changePage(e) {
      if (e < 1 || e > this.totalPage) return;
      this.cur = e;
      this.$emit("change", this.cur);
    },
  },
};
</script>
<style scoped>
.pager-item {
  max-width: 40px;
  @apply w-full border border-content-dark rounded cursor-pointer;
}
.pager-item.active {
  @apply text-main border-main border-2;
}
.ellipsis {
  background: no-repeat url(@/assets/ellipsis.png) center center;
}
.pre {
  background: no-repeat url(@/assets/icon/pager.svg) center center;
}
.next {
  transform: rotate(180deg);
  background: no-repeat url(@/assets/icon/pager.svg) center center;
}
</style>