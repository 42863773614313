<template>
  <div class="page-view mb-16">
    <div class="view--plan">
      <!-- 护理计划 -->
      <div class="page-title">
        <VTitle main="Care plan" sub="护理计划"></VTitle>
      </div>
      <div class="plan-list">
        <box
          class="plan cursor-pointer"
          v-for="item in carePlanList"
          :key="item.id"
          @click="gotoDetail(item.id)"
        >
          <div
            class="plan--cover flex-shrink-0"
            :style="{
              backgroundImage: `url()`,
            }"
          ></div>
          <div class="plan--info">
            <div class="plan--subtitle">{{ item.title_sub }}</div>
            <div class="plan--title">{{ item.title }}</div>
          </div>
        </box>
      </div>
    </div>
    <div class="view--faq">
      <!-- 关于产品 -->
      <div class="page-title">
        <VTitle main="FAQ'S" sub="关于产品"></VTitle>
      </div>
      <div>
        <div>
          <!-- <div>
            <p class="cate-title">产品分类</p>
            <div>选择器</div>
          </div> -->
          <div>
            <div
              class="question-item"
              v-for="(item, index) in FAQList"
              :key="item.id"
            >
              <div
                class="question"
                :class="{ open: questionOpen[index] }"
                @click="openQuestion(index)"
              >
                {{ item.question }}
              </div>
              <div class="answer">{{ item.answer }}</div>
            </div>
          </div>
        </div>
        <div>
          <!-- 右侧图片 -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseShadowBlock from "@/components/BaseShadowBlock/BaseShadowBlock";
export default {
  components: { box: BaseShadowBlock },
  data() {
    return {
      questionOpen: [false, false],
      carePlanList: [],
      FAQList: [],
    };
  },
  created() {
    this.getCarePlan();
    this.getFAQList();
  },
  methods: {
    gotoDetail(id) {
      this.$router.push("/nursing/" + id);
    },
    getCarePlan() {
      this.axios.post("product/carePlan").then((res) => {
        this.carePlanList = res.data.data;
      });
    },
    getFAQList() {
      this.axios.post("product/FAQ", { product_id: 1 }).then((res) => {
        this.FAQList = res.data.data;
        this.questionOpen = new Array(this.FAQList.length).fill(false);
      });
    },
    openQuestion(i) {
      this.questionOpen = this.questionOpen.map((item, index) =>
        index == i ? !item : item
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.view--plan {
  @apply px-3.5 md:px-12;

  .plan-list {
    @apply flex flex-col mt-3;
    @apply gap-y-3 md:gap-y-6;
    @apply xl:grid xl:grid-cols-2 xl:gap-x-7;

    .plan {
      @apply rounded-xl bg-content-light bg-opacity-5;
      @apply md:flex md:bg-opacity-0 md:p-5;
      @apply items-center;

      &--cover {
        @apply w-full pb-1/2 bg-no-repeat bg-cover rounded-xl;
        @apply md:w-52 md:h-52 md:pb-0;
      }

      &--info {
        @apply p-3 md:ml-6;
      }

      &--subtitle {
        @apply text-sm text-main font-normal;
        @apply md:inline-block md:px-5 md:py-2 md:rounded-full;
        @apply md:text-base md:font-bold md:bg-main md:bg-opacity-10;
      }

      &--title {
        @apply text-xs text-content-dark font-normal mt-1;
        @apply md:text-2xl md:font-bold md:mt-4;
      }
    }
  }
}
.view--faq {
  @apply px-3.5 md:px-12 mt-12;
}

.care-plan-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 48px;
  row-gap: 24px;
  margin-top: 24px;
}
.care-plan-item {
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 20px;
  cursor: pointer;
}
.left-cover {
  width: 204px;
  height: 204px;
  border-radius: 10px;
  overflow: hidden;
  background: #eee;
}
.right-text .tag {
  display: inline-block;
  padding: 0 24px;
  height: 37px;
  line-height: 37px;
  text-align: center;
  background: rgba(48, 136, 83, 0.1);
  border-radius: 18px;
  color: #006a53;
  font-weight: 700;
  font-size: 16px;
}
.right-text .name {
  margin-top: 16px;
  font-weight: 700;
  font-size: 24px;
  color: #445356;
}

.cate-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #445356;
}

.question-item {
  border: 1px solid rgba(68, 83, 86, 0.5);
  border-radius: 10px;
  box-sizing: border-box;
  margin-top: 20px;
}
.question {
  position: relative;
  line-height: 66px;
  font-weight: 700;
  font-size: 18px;
  color: #445356;
  margin-left: 20px;
}
.question::after {
  content: "";
  display: block;
  width: 8px;
  height: 7px;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  background: url(@/assets/open.png) no-repeat;
  transition: 0.2s;
}
.question.open::after {
  transform: translateY(-50%) rotate(180deg);
}
.question.open + .answer {
  height: auto;
  margin-bottom: 20px;
}
.answer {
  height: 0;
  overflow: hidden;
  transition: 0.2s;
  color: #445356;
  font-weight: 350;
  font-size: 14px;
  margin-left: 20px;
}
</style>