<template>
  <div class="v-title">
    <div class="text-2xl md:text-5xl" v-if="main">
      {{ main }}
    </div>
    <div class="text-sm md:text-2xl md:font-bold" v-if="sub">{{ sub }}</div>
  </div>
</template>

<script>
export default {
  props: ["main", "sub"],
};
</script>