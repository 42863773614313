import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import "./tailwind.css";
import element from './element'
import './element-variables.scss';

Vue.use(element)

axios.defaults.baseURL = "https://www.vipeel.com.cn/api";

Vue.use(VueAxios, axios);
Vue.config.productionTip = false;

import api from '@/api/api'
Vue.prototype.$api = api

import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

import "fullpage.js/vendors/scrolloverflow";
import Vuefullpage from "vue-fullpage.js";
Vue.use(Vuefullpage);

// 注册全局组件
import VTitle from "@/components/VTitle/VTitle";
Vue.component("VTitle", VTitle);
import VBtn from "@/components/VBtn/VBtn";
Vue.component("v-btn", VBtn);
import VImg from "@/components/VImg/VImg";
Vue.component('VImg', VImg);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
