<template>
  <div
      class="
        fixed
        top-1/2
        left-1/2
        z-50
        -translate-x-1/2 -translate-y-1/2
        w-4/5
        max-w-sm
        shadow-xl
      "
  >
    <div class="p-6 rounded-xl bg-white">
      <div class="flex justify-between">
        <div>
          <div class="font-extrabold text-2xl text-content-dark">Join</div>
          <div class="text-sm text-content-dark">登录/注册</div>
        </div>
        <div
            class="w-6 h-6 bg-cover cursor-pointer"
            @click="$store.commit('setLoginBoxState', false)"
            :style="{
              backgroundImage: `url(${require('@/assets/icon/close-full.png')})`,
            }"
        ></div>
      </div>
      <div class="mt-6 mx-auto w-2/3">
        <VImg height="100%" :src="qrcode" fit="cover">
          <div slot="error" class="text-sm text-content-dark flex justify-center items-center h-full bg-gray-100 cursor-pointer text-center" @click="getCode">
            <div v-if="loading">
              加载中……
            </div>
            <div v-else>
              二维码已失效<br />
              请点击此处刷新
            </div>
          </div>
          <div slot="placeholder" class="text-sm text-content-dark flex justify-center items-center h-full bg-gray-100">
            加载中……
          </div>
        </VImg>
        <div class="text-sm text-content-dark text-center mt-3.5">微信打开“扫一扫”进行登录</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      qrcode: '',
      authKey: '',
      loading: true
    }
  },
  mounted() {
    this.getCode()
  },
  beforeDestroy() {
    // 销毁前清除定时器和心跳
    clearTimeout(this.timer)
    clearInterval(this.heartbeat)
  },
  methods: {
    getCode() {
      this.loading = true
      this.$api.getQrcode()
          .then(res => {
            this.loading = false
            this.qrcode = res.xcx
            this.authKey = res.auth_key

            // 设置心跳 每1s检查是否授权
            clearInterval(this.heartbeat)
            this.heartbeat = setInterval(() => {
              this.$api.getPCToken(this.authKey)
                  .then(res => {
                    // 授权成功清除心跳和定时器
                    clearInterval(this.heartbeat)
                    clearTimeout(this.timer)
                    this.$cookies.set('token', res.token)
                    this.$cookies.set('user', {
                      nickname: res.user.nickname,
                      avatar: res.user.avatar
                    })
                    this.$store.commit('setLoginBoxState', false)
                  })
                  .catch(() => {})
            }, 1000)

            // 5分钟后失效
            clearTimeout(this.timer)
            this.timer = setTimeout(() => {
              this.qrcode = ''
              this.authKey = ''
              clearInterval(this.heartbeat)
            }, 300000)
          })
    }
  }
}
</script>

<style scoped>

</style>