import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/Home/HomeView.vue'
import ProductView from '../views/Product/ProductView.vue'
import FeedbackView from '../views/Feedback/FeedbackView.vue'
import NursingView from '../views/Nursing/NursingView.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'home',
    component: HomeView
}, {
    path: '/product',
    name: 'product',
    component: ProductView,
}, {
    path: '/product/:id',
    name: 'productDetail',
    component: () =>
        import ('../views/Product/ProductDetailView.vue')
}, {
    path: '/feedback',
    name: 'feedback',
    component: FeedbackView
}, {
    path: '/nursing',
    name: 'nursing',
    component: NursingView
}, {
    path: '/nursing/:id',
    name: 'nursingDetail',
    component: () =>
        import ('../views/Nursing/NursingDetailView.vue')
}, {
    path: '/news',
    name: 'news',
    component: () =>
        import ('../views/News/NewsView.vue')
}, {
    path: '/community/:id',
    name: 'communityDetail',
    component: () =>
        import ('../views/News/NewsDetailView.vue')
}, {
    path: '/partners',
    name: 'partners',
    component: () =>
        import ('../views/Partners/PartnersView.vue')
}, {
    path: '/introduction/:index',
    name: 'introductionDetail',
    component: () =>
        import ('../views/Introduction/IntroductionDetail.vue')
}, {
    path: '/introduction',
    name: 'introduction',
    component: () =>
        import ('../views/Introduction/IntroductionView.vue')
}, {
    path: '/download',
    name: 'download',
    component: () =>
        import ('../views/Download/DownloadView.vue')
}, {
    path: '/download/:id',
    name: 'downloadDetail',
    component: () =>
        import ('../views/Download/DownloadDetailView.vue')
}, {
    path: '/search',
    name: 'search',
    component: () =>
        import ('../views/Search/SearchView.vue')
}]

const router = new VueRouter({
    routes:routes
})

export default router