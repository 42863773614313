<template>
  <div
    class="
      product-exhibition-wrap
      relative
      rounded-xl
      overflow-hidden
      cursor-pointer
      group
    "
  >
    <div
      class="
        product-exhibition
        absolute
        top-0
        right-0
        bottom-0
        left-0
        grid grid-cols-2 grid-rows-2
      "
    >
      <div
        class="col-span-full flex items-center mt-7 mx-7 font-bold"
        :style="{ color: titleColor }"
      >
        {{ product.title }}
      </div>
      <div
        class="
          col-span-full
          flex
          items-center
          mt-7
          mx-7
          text-xs text-content-light
        "
      >
        {{ product.enTitle }}
      </div>
      <div class="flex items-end mb-7 mx-7 text-xl font-medium text-main">
        ￥{{ product.price }}
      </div>
      <div class="flex justify-end items-end">
        <img class="h-4/5 w-auto" :src="product.cover" alt="封面" />
      </div>
    </div>
    <div
      class="
        absolute
        top-0
        right-0
        bottom-0
        left-0
        flex
        justify-center
        items-center
        transition
        group-hover:bg-black group-hover:bg-opacity-40
      "
    >
      <button
        class="
          px-4
          py-2
          border border-white
          rounded-sm
          text-white text-xs
          transition
          opacity-0
          group-hover:opacity-100
          hover:bg-accent-blue
        "
        @click="getDateil"
      >
        BUY NOW →
      </button>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    product: {
      id: String,
      title: String,
      enTitle: String,
      price: String,
      cover: String,
    },
    titleColor: {
      default: "#445356",
    },
  },
  methods: {
    getDateil() {
      this.$router.push("/product/" + this.product.id);
    },
  },
};
</script>
<style scoped>
.product-exhibition-wrap {
  width: 100%;
  min-width: 200px;
  padding-bottom: 120%;
}
</style>