<template>
  <div class="w-full h-full p-3 shadow-lg lg:shadow-none hover:shadow-xl transition duration-300 rounded-md cursor-pointer" @click="$emit('click')">
    <VImg height="100%" :src="cover" :custom-class="['rounded-md']" fit="cover"></VImg>
    <div class="mt-3 flex flex-col">
      <p class="text-sm md:text-lg xl:text-2xl font-bold text-main">{{name}}</p>
      <p class="text-xs md:text-sm text-content-light">{{subtitle}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductItem",
  props: ['cover', 'name', 'subtitle', 'price'],
}
</script>