<template>
  <div
    class="sticky z-40 inset-x-0 top-0 backdrop-blur-lg transition"
    @mouseleave="openSubNav(false)"
    :class="{ 'bg-white': showSubNav }"
  >
    <div class="nav">
      <div class="nav-logo">
        <img :src="$store.state.siteConfig.logo"  alt=""/>
      </div>
      <ul class="nav-list">
        <li class="nav-list--item" @mouseenter="openSubNav(false)">
          <router-link to="/">首页</router-link>
        </li>
        <li class="nav-list--item" @mouseenter="openSubNav(true, 'introduction')">
          <router-link :to="path()">品牌介绍</router-link>
        </li>
        <li class="nav-list--item" @mouseenter="openSubNav(true, 'product')">
          <router-link to="/product">产品实力</router-link>
        </li>
        <li class="nav-list--item" @mouseenter="openSubNav(false)">
          <router-link to="/news">品牌新闻</router-link>
        </li>
<!--        <li class="nav-list&#45;&#45;item">-->
<!--          <router-link to="/partners">合作机构</router-link>-->
<!--        </li>-->
<!--        <li class="nav-list&#45;&#45;item">-->
<!--          <router-link to="/download">共享中心</router-link>-->
<!--        </li>-->
      </ul>
      <ul class="nav-menu">
        <li @click="gotoSearch">
          <img src="@/assets/navbar/search.svg"  alt=""/>
        </li>
        <li @click="setAuxiliary('brightness')">
          <img src="@/assets/navbar/light.svg"  alt=""/>
        </li>
        <li @click="setAuxiliary('invert')">
          <img src="@/assets/navbar/invert.svg"  alt=""/>
        </li>
        <li @click="clickUser">
          <img src="@/assets/navbar/user.svg"  alt=""/>
          <div class="absolute top-20 right-28" v-if="showUserBox">
            <User @logout="logout"></User>
          </div>
        </li>
        <li @click="openSideState">
          <img src="@/assets/navbar/menu.svg"  alt=""/>
        </li>
      </ul>
    </div>
    <SubNav ref="subNav"/>
  </div>
</template>

<script>
import SubNav from "./SubNav";
import User from "./components/User";
import {mapState} from "vuex";

export default {
  components: { SubNav, User },
  data() {
    return {
      showSubNav: false,
      showUserBox: false,
    }
  },
  computed: {
    ...mapState(['introduction'])
  },
  methods: {
    path() {
      return '/introduction/' + this.introduction.findIndex(item => item.title === '关于维塔丽')
    },
    openSubNav(isOpen, type) {
      this.showSubNav = isOpen
      if (isOpen) {
        this.$refs.subNav.open(type)
      } else {
        this.$refs.subNav.close()
      }
    },
    gotoSearch() {
      this.$router.push("/search");
    },
    setAuxiliary(name) {
      switch (name) {
        case "invert":
          this.$store.commit("changeInvert");
          break;
        case "brightness":
          this.$store.commit("changeBrightness");
          break;
      }
    },
    openSideState() {
      this.$store.commit("setSideState", true);
    },
    clickUser() {
      const user = this.$cookies.get('user')
      const token = this.$cookies.get('token')
      if (user && token) {
        this.showUserBox = !this.showUserBox
      } else {
        this.$store.commit('setLoginBoxState', true)
      }
    },
    logout() {
      this.showUserBox = false
      this.$cookies.remove('token')
      this.$cookies.remove('user')
    }
  },
};
</script>

<style lang="scss" scoped>
.nav {
  @apply h-16 md:h-24 flex items-center px-6 lg:px-14;

  .nav-logo {
    @apply h-10 md:h-12;

    & > img {
      @apply h-full max-w-none;
    }
  }

  .nav-list {
    @apply hidden;
    @apply md:flex;
    @apply flex-1 ml-12;

    &--item {
      @apply w-20 lg:w-28 2xl:w-40;
      @apply h-16 flex justify-center items-center cursor-pointer;
      @apply text-sm font-normal text-content-dark hover:text-main hover:font-bold;
    }
  }

  .nav-menu {
    @apply w-full flex items-center justify-end gap-6;
    @apply md:w-auto md:gap-12;

    & > li {
      @apply w-8 h-8 flex justify-center items-center cursor-pointer;

      & > img {
        -webkit-user-drag: none;
      }
    }

    & > li:nth-child(-n + 3) {
      @apply hidden;
      @apply xl:flex;
    }
  }
}
</style>